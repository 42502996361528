import { useContext } from 'react';

import Textarea from '../../../UI/Input/Textarea';
import Input from '../../../UI/Input/Input';

import { CreatorContext, TgContext } from '../../../settings/context';

import styles from "./OrderDetail.module.scss";
import { local } from '../../../localization/local';

interface OrderDetailInt {
    form: any,
}

export default function OrderDetail({ form }: OrderDetailInt) {

    const { tgApp } = useContext(TgContext);

    return (
        <section style={{ width: "100%" }}>
            <h1 className='title' style={{ marginBottom: "24px" }}>{local.detail}</h1>
            <div className={styles["order-detail__block"]}>
                <div className={styles["order-detail__group"]}>
                    <p className={styles["order-detail-small-text"]}>{local.name}</p>
                    <Input
                        type='text'
                        placeholder='Получатель'
                        form={form}
                        id={"name_for"}
                        name={"name_for"}
                    />
                    <p className={styles["order-detail-small-text"]}>
                        {/* {tgApp?.initDataUnsafe?.user.first_name ?? "Друг"}, оставь свое имя, если хочешь получить ответ для себя, или укажи имя того, для кого ты заказываешь. */}
                    </p>
                </div>
                <div className={styles["order-detail__theme"]}>
                    <h2 className={styles["order-detail-title"]}>{local.comment}</h2>
                    <div className={styles["order-detail__large-group"]}>
                        <p className={styles["order-detail-small-text"]}>
                            {local.author_know}
                        </p>
                        <div className={styles["order-detail__group"]}>
                            <Textarea
                                type='text'
                                form={form}
                                id={"description"}
                                name={"description"}
                            />
                            {(form.values.description.length>5 || form.values.description.length===5 )&&(
                                <p className={styles["order-detail-symbols"]} >
                                    {form.values.description.length}/250 {local.characters}
                                </p>
                            )}
                            {(form.values.description.length<5)&&(
                                <p className={styles["order-detail-symbols"]} style={{color: 'red'}}>
                                    {form.values.description.length}/250 {local.characters}
                                </p>
                            )}
                        </div>
                    </div>
                    <p className={styles["order-detail-small-text"]}>
                        {local.recommendations}
                    </p>
                </div>
            </div>
        </section>
    )
}
