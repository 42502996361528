import { useContext } from 'react';

import CircleBlock from '../CircleBlock/CircleBlock';

import { CreatorContext, TgContext } from '../../../settings/context';

import styles from "./Heading.module.scss";

interface HeadingInt {
    link: string
}

export default function Heading({ link }: HeadingInt) {

    const { creatorInfo } = useContext(CreatorContext);
    const { tgApp } = useContext(TgContext);

    return (
        <div className={styles["heading"]}>
            <div className={styles["heading__block"]}>
                <CircleBlock link={link} type="img" />
                <span className={styles["heading-split"]}>x</span>
                <CircleBlock link={creatorInfo.photo_url} type="img" />
            </div>
            <h2 className={styles["heading-title"]}>{creatorInfo.name}</h2>
            <p className={styles["heading-description"]}>{creatorInfo.type_user}</p>
        </div>
    );
}