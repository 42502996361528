import { Outlet } from "react-router";

export default function MainLayout() {

    return (
        <div className='content'>
            <main className='container content center'>
                <Outlet/>
            </main>
        </div>
    );
}
