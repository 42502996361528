import { useContext,Dispatch, SetStateAction } from 'react';
import check from "../../../images/check.svg";
import { paymentImage } from '../../../assets/project/paymentItems';
import { TgContext } from '../../../settings/context';
import styles from "./Order.module.scss";
import { local } from '../../../localization/local';

interface OrderItemInt {
    data: any,
    checked: number,
    server?: boolean,
    index: number,
    setChecked: Dispatch<SetStateAction<number>>
}

export default function OrderItem({ data, server = true, checked, index, setChecked }: OrderItemInt) {
    
    const { tgApp } = useContext(TgContext);

    return (
        <div className={`${(checked !== index) ? styles["order-item"] : styles["order-item_active"]}`} onClick={() => setChecked(Number(index))}>
            <div className={styles["order-item__container"]}>
                <div className={styles["order-item-inner"]}>
                    {(server) ? <div className={styles['order-item-inner-speed']} dangerouslySetInnerHTML={{ __html: data.svg }}></div> : <div dangerouslySetInnerHTML={{ __html: data.image }}></div>}
                    
                    {/*  */}
                    <div className={styles["order-item-price"]}>
                        <p className={styles["order-item-price-title"]}>
                            {data.title}
                        </p>
                        <p className={styles["order-item-price-description"]}>{data.date} {(!server) ? ` ${local.payment_code}` : ` ${local.payment_code}`}{data.price} </p>
                    </div>
                </div>
                {(checked === index) && (
                    <div className={styles["order-item-check"]}>
                        <img src={check} alt="checked" />
                    </div>
                )}
            </div>
        </div>
    )
}
