import { local } from "../localization/local";

export default class Functions {
	static delimeterPrice(price: string) {
		if (price.length <= 3) {
			return price;
		}
		price = String(price).trim();
		let returnValue = '';
		let counter = 0;
		for (let i = price.length - 1; i >= 0; i--) {
			returnValue += price.charAt(i);
			counter++;
			if (counter === 3) {
				returnValue += ' ';
				counter = 0;
			}
		}

		return returnValue.split('').reverse().join('').trim();
	}

	static hasClass(target: any, nameOfClass: string): any {
		const rec = this.getClassRecursion(target, nameOfClass);
		if (nameOfClass == rec) {
			return true;
		} else {
			return false;
		}
	}

	static getClassRecursion(target: any, nameOfClass: string): any {
		if (target.parentNode === null) {
			return false;
		}
		if (target.classList.contains(nameOfClass)) {
			return nameOfClass;
		} else {
			return this.getClassRecursion(target.parentNode, nameOfClass);
		}
	}

	static getPathParams = (path: string): Array<string> => {
		return path.split('/');
	};

	static sortArray = (
		a: object,
		b: object,
		type: boolean,
		property: string
	): any => {
		if (a[property as keyof typeof a] > b[property as keyof typeof b]) {
			return type ? 1 : -1;
		}
		if (a[property as keyof typeof a] < b[property as keyof typeof b]) {
			return type ? -1 : 1;
		}
		return 0;
	};

	static filesValidation(
		files: Array<File>,
		fileTypes: string,
		multiply: boolean
	) {
		const fileTypesList = fileTypes.split(', ');

		if (multiply) {
			const returnArray = [];
			for (let item of files) {
				if (fileTypesList.includes(item.type)) {
					returnArray.push(item);
				}
			}
			return returnArray;
		} else {
			return fileTypesList.includes(files[0].type) ? files[0] : undefined;
		}
	}

	static fileSizeConverter(size: number) {
		const convertSize = size / 1024; //kilobites

		return convertSize < 1024
			? `${convertSize.toFixed(0)} кб`
			: `${Number(convertSize / 1024).toFixed(1)} мб`;
	}

	static convertEventKeys(key: string) {
		const dict = {
			holiday: 'Праздник',
			birthday: 'День рождения',
			love: 'Признание в любви',
			positive: 'Ободрение',
			roast: 'Прожарка',
			advice: 'Совет',
			question: 'Вопрос',
		};

        //@ts-ignore
        return dict[key];
	}

	static getOrderEnum = (type: string) => {
		if (type){
            switch(String(type).toLowerCase()){
                case "question": return local.question_button_name; 
                case "audio": return local.audio_button_name; 
                case "circle": return local.circle_button_name; 
                case "video": return local.video_button_name; 
                case "call": return local.call_button_name;
                default: return local.question_button_name;
            }
        }
        return "Получить";
	}

	static getOrderText = (type: string, influncerName: string) => {
		if (type){
            switch(String(type).toLowerCase()){
                case "question": return `Привет, ${influncerName}! Я всегда хотел спросить тебя о ____`; 
                case "audio": return `Привет, ${influncerName}! Пожалуйста, запиши мне аудиосообщение на о ____`; 
                case "circle": return `Привет, ${influncerName}! Пожалуйста, запиши кружок о ____`; 
                case "video": return `Привет, ${influncerName}! Пожалуйста, сделай для меня видео о ____`; 
                case "call": return `Привет, ${influncerName}! Пожалуйста, давай найдем время для звонка о ____. Мое свободное время от __ и до ___ в ____ дни недели. `;
                default: return `Привет, ${influncerName}! Пожалуйста, расскажи мне о ____`;
            }
        }
        return `Привет, ${influncerName}! Пожалуйста, расскажи мне о ____`;
	}
}
