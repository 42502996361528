import LocalizedStrings from 'react-localization';
export let local = new LocalizedStrings({
    en:{
        payment_code: "$",
        hello:"Hello",
        friend: "friends",
        left: "Left",
        ops:"Oops, not anymore",
        title_1_block: "Let's talk",
        question: "What do you want to ask about?",
        audio: "What should I talk about?",
        circle: "What should the video be with?",
        video:"What should the video be with?",
        call:"What do you want to talk about?",
        detail: "Details:",
        name: "Name:",
        comment:"Comments:",
        author_know: "Things creator should know:",
        characters:"characters",
        recommendations:"Tip: provide the creator with more details about the request, the more details you provide the better the content will be!",
        payment_selection: "Checkout",
        speed_order: "Delivery:",
        payment_method: "Payment",
        cost_order: "Order:",
        delivery:"Delivery:",
        total:"Total:",
        go_to_payment: "Pay",
        question_button_name: "Ask a question",
        audio_button_name: "Get a voice message",
        circle_button_name: "Get a short video",
        video_button_name:"Get a long video",
        call_button_name:"Get a call",
        tell_us_more: "Tell us more...",
        yookassa:"YooKassa (Russia)",
        stripe:"Stripe (World)"
    },
    ru: {
        payment_code: "₽",
        hello:"Привет",
        friend: "друг",
        left: "Осталось",
        ops:"Упс, больше нет",
        title_1_block: "Давай общаться",
        question: "О чём ты хочешь задать вопрос?",
        audio: "О чём ты хочешь аудио?",
        circle: "О чём ты хочешь кружок?",
        video:"О чём ты хочешь видео?",
        call:"О чём ты хочешь разговор?",
        detail: "Детали заказа:",
        name: "Имя:",
        comment:"Комментарии к заказу",
        author_know: "Что автор должен знать:",
        characters:"символов",
        recommendations:"Рекомендации: расскажите как бы вы хотели, чтобы автор выполнил задание. Чем детальнее будет расписан заказ, тем качественнее получится результат.",
        payment_selection: "Выбор оплаты",
        speed_order: "Скорость выполнения заказа",
        payment_method: "Способ оплаты",
        cost_order: "Стоимость заказа:",
        delivery:"Доставка:",
        total:"Итого:",
        go_to_payment: "Перейти к оплате",
        question_button_name: "Задать вопрос",
        audio_button_name: "Получить аудио",
        circle_button_name: "Получить кружок",
        video_button_name:"Получить видео",
        call_button_name:"Созвониться",
        tell_us_more: "Введите текст...",
        yookassa:"Юкасса (РФ)",
        stripe:"Stripe (World)"
    },
    
    
});