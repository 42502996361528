import { useContext, Dispatch, SetStateAction } from 'react';

import RadioGroup from '../RadioGroup/RadioGroup';

import { CreatorContext, TgContext } from '../../../settings/context';

import styles from "../Task/Task.module.scss";
import { local } from '../../../localization/local';

interface TopicTypeInt {
    form: any,
    id: string,
    selectedTopicType: number | null,
    selectedTheme: number | null,
    setSelectedTopicType: Dispatch<SetStateAction<any>>
}

export default function TopicType({form, id, selectedTheme, selectedTopicType, setSelectedTopicType}: TopicTypeInt) {

    const { creatorInfo } = useContext(CreatorContext);
    const tgApp = useContext(TgContext);

    const getTopicText = (type: string) => {
        if (type){
            switch(String(type).toLowerCase()){
                case "question": return local.question; 
                case "audio": return local.audio; 
                case "circle": return local.circle; 
                case "video": return local.video; 
                case "call": return local.call;
                default: return local.call;
            }
        }
        return "Что мне стоит сделать для тебя?";
    }
    
    return (
        <div className='block'>
            <div className={styles["task__block"]}>
                <h2 className='subtitle'>
                    {getTopicText(creatorInfo?.order[selectedTheme].theme)}
                </h2>
            </div>
            <RadioGroup
                form={form}
                itemsList={creatorInfo.order[selectedTheme].type_order}
                selectedTheme={selectedTopicType}
                setSelectedTheme={setSelectedTopicType}
            />
        </div>
    );
}
