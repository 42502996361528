import ScrollContainer from 'react-indiana-drag-scroll';
import { useContext } from 'react';

import { TgContext } from '../../../settings/context';

import clipboard from "../../../images/info/clipboard.png";
import emojies from "../../../images/info/emojies.png";
import emoji from "../../../images/info/emoji.png";
import cube from "../../../images/info/cube.png";
import lock from "../../../images/info/lock.png";

import styles from "./InfoBlock.module.scss";

export default function InfoBlock() {

    const { tgApp } = useContext(TgContext);

    const openLink = (link: string) => {
        tgApp.openLink(link, { try_instant_view: true });
    }

    return (
        <div className={styles["info-block"]}>
            <ScrollContainer className={styles["info-block__container"]}>
                <div className={styles["info-block-item"]} onClick={() => openLink("https://teletype.in/@directblog/whatisdirect")}>
                    <p className={styles["info-block-item-text"]}>
                        Что такое<br />Direct?
                    </p>
                    <img src={emojies} alt="emojies" />
                </div>
                <div className={styles["info-block-item"]} onClick={() => openLink("https://teletype.in/@directblog/types")}>
                    <p className={styles["info-block-item-text"]}>
                        Какие типы<br />заказов<br />бывают?
                    </p>
                    <img src={clipboard} alt="clipboard" />
                </div>
                <div className={styles["info-block-item"]} onClick={() => openLink("https://teletype.in/@directblog/howto")}>
                    <p className={styles["info-block-item-text"]}>
                        Как<br />оформить<br />заказ?
                    </p>
                    <img src={emoji} alt="emoji" />
                </div>
                <div className={styles["info-block-item"]} onClick={() => openLink("https://teletype.in/@directblog/safety")}>
                    <p className={styles["info-block-item-text"]}>
                        Гарантии и<br />безопасность
                    </p>
                    <img src={lock} alt="lock" />
                </div>
                <div className={styles["info-block-item"]} onClick={() => openLink("https://teletype.in/@directblog/faq")}>
                    <p className={styles["info-block-item-text"]}>
                        Часто<br />задаваемые<br />вопросы
                    </p>
                    <img src={cube} alt="cube" />
                </div>
            </ScrollContainer>
        </div>
    )
}
