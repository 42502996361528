import { useState, useEffect, useContext, Dispatch, SetStateAction } from 'react';

import RadioGroup from '../RadioGroup/RadioGroup';
import Select from '../../../UI/Select/Select';

import { CreatorContext, TgContext } from '../../../settings/context';
import {local} from "../../../localization/local";
import styles from "./Task.module.scss";

interface TaskInt {
    form: any,
    id: string,
    selectedTheme: number | null,
    setSelectedTheme: Dispatch<SetStateAction<any>>
}

export default function Task({ form, id, selectedTheme, setSelectedTheme }: TaskInt) {

    const [contentTypes, setContentTypes] = useState<any>({});

    const { creatorInfo } = useContext(CreatorContext);
    const { tgApp } = useContext(TgContext);

    const getContentTypes = (list: any) => {
        const items = Object.keys(list.order);
        const contentTypesList: any = {};
        items.forEach((item: any) => {
            if (item === "video") {
                contentTypesList[item] = "Видео";
            }
            if (item === "photo") {
                contentTypesList[item] = "Фото";
            }
            if (item === "circle") {
                contentTypesList[item] = "Кружок";
            }
        });

        setContentTypes(contentTypesList);
    }

    const selectCallback = () => {
        setSelectedTheme(null);
    }

    useEffect(() => {
        getContentTypes(creatorInfo);
    }, [creatorInfo]);

    useEffect(() => {
        if (creatorInfo){
            getContentTypes(creatorInfo);
        }
    }, []);

    return (
        <div className='block'>
            <div className={styles["task__block"]}>
                <h2 className='subtitle'>
                    {local.title_1_block}, {tgApp?.initDataUnsafe?.user?.first_name ?? local.friend}?
                </h2>
            </div>
            <RadioGroup
                form={form}
                itemsList={creatorInfo.order}
                additional={true}
                selectedTheme={selectedTheme}
                setSelectedTheme={setSelectedTheme}
            />
        </div>
    )
}
