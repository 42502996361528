import React from 'react';

import complete from "../../../images/payment-complete.svg";
import error from "../../../images/payment-error.svg";

import styles from "./PaymentConfirmed.module.scss";

type payment = "confirm" | "error" | "connectionError";
interface PaymentConfirmed {
    mode: payment
}

export default function PaymentConfirmed({ mode }: PaymentConfirmed) {
    return (
        <div className={styles["payment-confirmed"]}>
            <img src={(mode === "confirm") ? complete : error} alt="complete" />
            <div className={styles["payment-confirmed__block"]}>
                <h2 className={styles["payment-confirmed-title"]}>
                    {(mode === "confirm") && "Оплата потверждена!"}
                    {(mode === "error") && "Оплата отменена"}
                    {(mode === "connectionError") && "Что-то пошло не так!"}
                </h2>
                <p className={styles["payment-confirmed-text"]}>
                    {(mode === "confirm") && "Мы отправили информацию о заказе личным сообщением в чат."}
                    {(mode === "error") && 'Нажмите "Назад" и проверьте правильность введенных деталей.'}
                    {(mode === "connectionError") && "Попробуйте сделать запрос позже."}
                </p>
            </div>
        </div>
    );
}
