import React from 'react';

import { ScrollbarInt } from '../../settings/interfaces';

import styles from "./Scrollbar.module.scss";

export default function Scrollbar({children}: ScrollbarInt){
  return (
    <div className={styles["DAS-scrollbar"]}>
        {children}
    </div>
  )
}
