import React, { useState } from 'react';

import ErrorBox from '../ErrorBox/ErrorBox';

import { InputInt } from '../../settings/interfaces';

import InputPassword from "../../assets/basic/UI/input-password.svg";
import InputCancel from "../../assets/basic/UI/input-cancel.svg";
import InputSearch from "../../assets/basic/UI/input-search.svg";

import styles from './Input.module.scss';
import { local } from '../../localization/local';

export default function Input(
    {
        type = "text",
        value = "",
        placeholder = local.tell_us_more,
        id,
        name,
        form,
        error = true,
        label = "",
        border = true,
        disabled = false,
        className = "",
        style = {},
        callback,
        inputChange,
    }: InputInt) {

    const [showPass, setShowPass] = useState("password");

    const passwordHandler = () => {
        setShowPass(state => (state === "text") ? "password" : "text");
    }

    const getType = () => {
        switch (type) {
            case "text":
            case "search":
            case "limit":
                return "text";
            case "password":
                return showPass;
            case "time": 
                return "time";
            default:
                return "text";
        }
    };

    return (
        <div className={styles["DAS-input-box"]}>
            <div
                className={`${styles["DAS-input"]}
                 ${(border) ? styles["DAS-input-border"] : ""} 
                 ${(label !== "") ? styles["DAS-input-non-label"] : ""} 
                 ${(type === "search") ? styles["DAS-input-search"] : ""} 
                 ${className}`}
                style={style}
            >
                {(type === "search") && <InputSearch />}
                <div className={styles["DAS-input-main"]}>
                    {(label !== "") &&
                        <label
                            htmlFor={id}
                            className={`${styles["DAS-input-label"]} ${(error && form &&
                                form.errors[name as keyof typeof form.errors] &&
                                form.touched[name as keyof typeof form.touched]) ? styles["DAS-input-error"] : ""}`
                            }
                        >
                            {label}
                        </label>
                    }
                    <input
                        type={getType()}
                        className={styles["DAS-input-text"]}
                        value={(form) ? (value === "") ? form.values[name as keyof typeof form.values] : value : value}
                        onChange={(form) ? form.handleChange : (inputChange) ? (e: any) => inputChange(e.target.value) : null}
                        placeholder={placeholder}
                        name={name}
                        id={id}
                        disabled={disabled}
                    />
                </div>
                {(type === "password") && <div onClick={passwordHandler}><InputPassword /></div>}
            </div>
            <ErrorBox
                error={error}
                form={form}
                name={name}
            />
        </div>
    )
}
