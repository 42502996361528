import { Dispatch, SetStateAction } from 'react';

import MainModule from '../components/project/MainModule/MainModule';

interface MainPageInt{
    stage: number,
    setStage: Dispatch<SetStateAction<number>>
}

export default function MainPage({ stage, setStage }: MainPageInt) {
    return (
        <MainModule stage={stage} setStage={setStage} />
    )
}