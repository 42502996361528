import { useRef, Dispatch, SetStateAction } from 'react';
import ReactPlayer from 'react-player';

import anonymous from "../../../images/anonymous.png";

import styles from "./CircleBlock.module.scss";


type circle = "img" | "video";
interface CircleBlockInt {
    link: any;
    type: circle;
    index?: number;
    canPlay?: number | undefined;
    playHandler?: (index: number) => {};
}

export default function CircleBlock({ link, type, index = 0, canPlay = 0, playHandler}: CircleBlockInt) {

    const circleRef = useRef();

    return (
        <div className={styles["circle-block"]}>
            {(type === "img") && (
                <img src={link ?? anonymous} alt="circle" />
            )}
            {(type === "video") && (
                <ReactPlayer
                    url={link}
                    playsinline={true}
                    loop={true}
                    playing={(index === canPlay)}
                    controls={false}
                    ref={circleRef}
                    onClick={() => playHandler(index)}
                    onTouch={() => playHandler(index)}
                />
            )}
        </div>
    )
}
