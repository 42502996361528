import React, { useState } from 'react';

import Scrollbar from '../Scroolbar/Scrollbar';
import ErrorBox from '../ErrorBox/ErrorBox';

import { InputInt } from '../../settings/interfaces';

import styles from './Input.module.scss';
import { local } from '../../localization/local';

export default function Textarea(
    {
        type = "text",
        value = "",
        placeholder = local.tell_us_more,
        id,
        name,
        form,
        error = true,
        label = "",
        border = true,
        disabled = false,
        className,
        inputChange,
    }: InputInt) {


    return (
        <div className={`${styles["DAS-input-box"]} ${styles["DAS-input-box-textarea"]}`}>
            <div
                className={`${styles["DAS-input"]} 
                ${styles["DAS-input-textarea"]} 
                ${(border) ? styles["DAS-input-border"] : ""} 
                ${(label !== "") ? '' : styles["DAS-input-non-label"]} 
                ${(type === "search") ? styles["DAS-input-search"] : ""}                
                ${className}`}
            >
                <div className={styles["DAS-input-main"]}>
                    {(label !== "") &&
                        <label
                            htmlFor={id}
                            className={`${styles["DAS-input-label"]} ${(error && form &&
                                form.errors[name as keyof typeof form.errors] &&
                                form.touched[name as keyof typeof form.touched]) ? styles["DAS-input-error"] : ""}`
                            }
                        >
                            {label}
                        </label>
                    }
                    <Scrollbar>
                        <textarea
                            className={`${styles["DAS-input-text"]} ${styles["DAS-input-text-textarea"]}`}
                            value={(form) ? (value === "") ? form.values[name as keyof typeof form.values] : value : value}
                            onChange={(form) ? form.handleChange : (inputChange) ? (e: any) => inputChange(e.target.value) : null}
                            placeholder={placeholder}
                            maxLength={250}
                            name={name}
                            id={id}
                            disabled={disabled}
                        />
                    </Scrollbar>
                </div>
            </div>
            <ErrorBox
                error={error}
                form={form}
                name={name}
            />
        </div>
    )
}
