import { useEffect, useContext, Dispatch, SetStateAction } from 'react';

import OrderItem from './OrderItem';

import { CreatorContext } from '../../../settings/context';

import stripe_card from "../../../images/stripe_card.png";

import web_card from "../../../images/web_card.png";
import { paymentImage_1 } from '../../../assets/project/paymentItems_1';
import { paymentImage_2 } from '../../../assets/project/paymentItems_2';
import styles from "./Order.module.scss";
import { local } from '../../../localization/local';

interface OrderInt {
    form: any,
    selectedTheme: number | null,
    payment: number | null,
    selectedTopicType: number | null,
    checked: number,
    setChecked: Dispatch<SetStateAction<number>>,
    setPayment: Dispatch<SetStateAction<number>>
}

export default function Order({ form, selectedTheme, selectedTopicType, checked, setChecked, payment,setPayment }: OrderInt) {

    const { creatorInfo } = useContext(CreatorContext);
    
    return (
        <div className={styles["order"]}>
            <h1 className={styles["order-title"]}>
                {local.payment_selection}
            </h1>
            <div className={styles["order__block"]}>
                <h2 className={styles["order-subtitle"]}>
                    {local.speed_order}
                </h2>
                <div className={styles["order__block"]}>
                    {creatorInfo && creatorInfo?.order[Number(selectedTheme)]?.type_order[Number(selectedTopicType)]?.cost.map((item: any, index: number) => {
                        return <OrderItem
                            data={item}
                            index={index}
                            checked={checked}
                            setChecked={setChecked}
                            key={index}
                        />
                    })}
                </div>
            </div>
            <div className={styles["order__block"]}>
                <h2 className={styles["order-subtitle"]}>
                    {local.payment_method}
                </h2>
                <div className={styles["order__block"]}>
                    <OrderItem
                        data={{
                            id: 1,
                            title: local.yookassa,
                            price: "VISA, MasterCard, МИР",
                            image: paymentImage_2
                        }}
                        index={1}
                        server={false}
                        checked={payment}
                        setChecked={setPayment}
                    />
                    <OrderItem
                        data={{
                            id: 2,
                            title: local.stripe,
                            price: "VISA, MasterCard",
                            image: paymentImage_1
                        }}
                        index={2}
                        server={false}
                        checked={payment}
                        setChecked={setPayment}
                    />
                </div>
            </div>
        </div>
    )
}
