import { useContext } from 'react';
import Typewriter from 'typewriter-effect';

import { CreatorContext, TgContext } from '../../../settings/context';

import linkImage from "../../../assets/project/link-image.svg";

import styles from "./HeadingInfo.module.scss";
import LocalizedStrings from 'react-localization';
import {local} from "../../../localization/local";
export default function HeadingInfo() {

    const { creatorInfo } = useContext(CreatorContext);
    const { tgApp } = useContext(TgContext);
    
    
    return (
        <div className={styles["heading-info"]}>
            <div className={styles["heading-info__block"]}>
                <div className={styles["heading-info-main"]}>
                    <div className={styles["heading-info-left"]}>
                        <div className={styles["heading-info-content"]}>
                            <p className={styles["heading-info-title"]}>{creatorInfo.name}</p>
                            <p className={styles["heading-info-description"]}>
                                <Typewriter
                                    options={{
                                        delay: 50,
                                        strings: `${local.hello}, ${tgApp?.initDataUnsafe?.user?.first_name ?? local.friend}! ` + creatorInfo.description ?? "",
                                        autoStart: true,
                                        loop: false,
                                    }}
                                />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
